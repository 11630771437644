import React, { useState, useRef } from 'react'
import useInfiniteScroll from '@hooks/use-infinite-scroll'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Typography, SvgIcon, Grid, Button } from '@lib/ui/core'
import ShrinkedList from '@components/Offer/ShrinkedList'
import Spinner from '@components/Spinner'
import { parseSiteConfig, parseOfferSubcategory } from '@services/prismic/helpers'
import { hasPath } from 'ramda'
import { linkResolver } from "@utils/link-resolver"
import Link from "@components/Link"
import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'

const SubcategoryBlock = () => {
  const existedIndex = (selected, check) => (selected.findIndex((item) => item.offer_subcategory.prismicId === check.prismicId))
  const { allPrismicOfferSubcategory, allPrismicSiteConfig } = useStaticQuery(graphql`
  query {
    allPrismicSiteConfig {
      nodes {
        data {
          featured_offer_subcategories {
            offer_subcategory {
              document {
                ... on PrismicOfferSubcategory {
                  id
                  data {
                    name
                  }
                  prismicId
                  uid
                }
              }
            }
          }
        }
      }
    }
    allPrismicOfferSubcategory {
      nodes {
        data {
          name
          offer_category {
            uid
            type
          }
          image {
            url
          }
          image_color
        }
        uid
        prismicId
        type
      }
    }
  }
`)
  const { featured_offer_subcategories: featuredSubcategories } = hasPath(['nodes'], allPrismicSiteConfig) && parseSiteConfig(allPrismicSiteConfig.nodes[0])
  const subcategories = parseOfferSubcategory(allPrismicOfferSubcategory.nodes)
  
  return (
    <>
      <Box boxShadow={9} borderRadius="1rem" height="100%" width="300px" mx="auto" px={2}>
        <Box display="flex" justifyContent="space-between" py={{xs: 2, md:3}} alignItems="center">
          <SvgIcon component={Logo} fontSize="2rem" mr={1} />
          <Typography variant="h2" component="h2">
            優惠類別
          </Typography>
          <Box ml="auto"></Box>
        </Box>
        <Box display="flex" flexWrap="wrap" pb={6}>
          {subcategories.sort(()=>Math.random() - 0.5).slice(0, 10).map((subcat) => <Box key={subcat.prismicId} mb={1} bgcolor={(existedIndex(featuredSubcategories, subcat) > -1 && "primary.main") || "grey.100"} px={1.5} py={1} mr={1} borderRadius="1rem" style={{ cursor: 'pointer' }} item_name={subcat.name} item_type="offer_sub_category">
           <Link
            to={linkResolver(subcat)}
            data-ele-name="offer-subcategory"
            color="text.primary"
            fontWeight="400"
          >{subcat.name}</Link>
          </Box>)}
        </Box>
      </Box>
    </>
  )
}

export default SubcategoryBlock
