/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from 'gatsby'
import Layout from '@layouts/MainLayout'
import Container from '@layouts/Container'
import Grid from '@lib/ui/core/Grid'
import SEO from '@components/SEO'
import Box from '@lib/ui/core/Box'
import Link from '@components/Link'
import Typography from '@lib/ui/core/Typography'
import OfferList from '@components/Offer/List'
import MobileOfferList from '@components/Offer/List/MobileList'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import SearchFilter from "@components/Search/SearchFilter"
import { parseOffer, parseSiteConfig, prismicClient } from "@services/prismic/helpers"
import { getQueryString, delParam, getQueryArray } from '@utils/url'
import SearchInput from "@components/Search/SearchInput"
import FilteredBanner from "@components/FilteredBanner"
import FilteredResult from "@components/Search/FilteredResult"
import qs from 'qs'
import Spinner from "@components/Spinner"
import DfpAdSlot from '@components/Ads/DfpAdSlot'
import FacebookPage from "@components/Widget/FacebookPage"
import SubcategoryBlock from '@components/Offer/SubcategoryBlock'
import { isEmpty } from 'ramda'

const path = '/offers'
const OffersPage = ({ data, location }) => {
  if (!data) 
    return null
  const docs = data.allPrismicOffer

  const searchPayload = useStoreState(
    ({ offerSearch }) => offerSearch.searchPayload
  )
  const searchLoading = useStoreState(
    ({ offerSearch }) => offerSearch.isLoading
  )

  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )

  const showSearchDrawer = useStoreState(
    ({ offerSearch }) => offerSearch.showSearchDrawer
  )


  const [total, setTotal] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)

  // Load Credit Cards at index page

  const urlParam = getQueryArray()
  const { page, ...restParam } = urlParam
  const filterParams = qs.stringify(restParam, { encode: false, arrayFormat: 'repeat' })

  const [isMobile, setIsMobile] = useState(true)
  const windowGlobal = typeof window !== 'undefined' && window // eslint-disable-line
  const deviceWidth = windowGlobal ? windowGlobal.document.documentElement.clientWidth : 0

  useEffect(() => {
    setIsMobile(deviceWidth <= 768)
  }, [deviceWidth])

  const offersNodes = docs.nodes
  const sponsor = parseSiteConfig(data.siteConfig.nodes[0].data.offer_listing_page_sponsor_offers)

  const tzoffset = (new Date()).getTimezoneOffset() * 60000
  const today = (new Date(Date.now() - tzoffset)).toISOString().substring(0, 10)
  const sponsoredOffers = sponsor.filter((o) => o.isBroken !== true && o.date <= today)

  sponsoredOffers.forEach(function(sponsorItem){
    sponsorItem.is_sponsored = true
  });
  const searchSponsored = parseSiteConfig(data.siteConfig.nodes[0].data.search_result_page_sponsor_offers)
  const searchSponsoredOffers = searchSponsored.filter((o) => o.isBroken !== true && o.date <= today)

  searchSponsoredOffers.forEach(function(sponsorItem){
    sponsorItem.is_sponsored = true
  });

  const { currentPage, pageCount } = docs.pageInfo
  if (docs.pageInfo.currentPage === 1 && offersNodes.length < 21) {
    if(sponsoredOffers.length === 1) {
      offersNodes.splice(0, 0, sponsoredOffers[0])
    } else if (sponsoredOffers.length > 1) {
      offersNodes.splice(0, 0, sponsoredOffers[0])
      offersNodes.splice(5, 0, sponsoredOffers[1])
    }
  } else if  (docs.pageInfo.currentPage === 2 && offersNodes.length < 21) {
    if (sponsoredOffers.length === 3) {
      offersNodes.splice(0, 0, sponsoredOffers[2])
    } else if (sponsoredOffers.length > 3) {
      offersNodes.splice(0, 0, sponsoredOffers[2])
      offersNodes.splice(5, 0, sponsoredOffers[3])
    }
  }
  return (
    <Layout headerTitle="最新優惠" type="offer">
      <SEO
        title="最新優惠"
      />
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <Container>
        <Box mb={2} mt={4}>
          <Typography variant="h2">最新優惠</Typography>
        </Box>
        <SearchFilter height="3rem" />
        <Grid container spacing={2} my={{ xs: 2, sm: 4 }} data-ele-section="category-list">
          {/* {posts.map((post) => <Box key={post.prismicId} mb={2.5}><Post post={post} fullWidth /></Box>)} */}
          {!isEmpty(urlParam) ? (
          <Grid item xs={12} sm={12} md={8}>
            <Box mb={{ xs: 2, sm: 4 }}>
              <FilteredBanner number={total} />
            </Box>
            <Box>
              <FilteredResult setTotal={setTotal} pageNumber={searchPayload.page} sponsoredOffers={searchSponsoredOffers} filterParams={filterParams} location={location} />
            </Box>
          </Grid>
          ) : (
          <Grid item xs={12} sm={12} md={8}>
            <OfferList offers={parseOffer(offersNodes)} fullWidth eventCategory="offer_listing_sponsor_offer"/>
            <Box textAlign="center" spacing={2} display="flex" justifyContent="center">
              <Pagination
                page={currentPage}
                count={pageCount}
                renderItem={(item) => (
                  <PaginationItem
                    component={Link}
                    to={`${path}/${item.page === 1 ? '' : `${item.page}`}`}
                    {...item}
                  />
                )}
              />
            </Box>
          </Grid>
          )}
          <Grid item xs={0} sm={0} md={4} direction="column">
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <DfpAdSlot adUnit="Web_JetsoBee/jetsobee_ros_lrec4_300x250" sizes={[300, 250]} />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <FacebookPage />
            </Box>
            <Box mb={4} textAlign="center" display={{ xs: 'none', md: 'block' }}>
              <SubcategoryBlock />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default OffersPage

export const query = graphql`
  query OffersListQuery($limit: Int!, $skip: Int!, $today: Date) {
    siteConfig: allPrismicSiteConfig {
      nodes {
        data {
          offer_listing_page_sponsor_offers {
            data: offer {
              url
              uid
              id
              isBroken
              document {
                ... on PrismicOffer {
                  prismicId
                  uid
                  type
                  data {
                    date
                    offer_category {
                      document {
                        ... on PrismicOfferCategory {
                          data {
                            name
                          }
                          uid
                          type
                        }
                      }
                    }
                    offer_subcategory {
                      document {
                        ... on PrismicOfferSubcategory {
                          uid
                          type
                          data {
                            name
                            offer_category {
                              uid
                              type
                            }
                            image {
                              url
                            }
                            image_color
                          }
                        }
                      }
                    }
                    featured_images {
                      image {
                        thumbnails {
                          thumbnail {
                            fluid(maxWidth: 240) {
                              ...GatsbyPrismicImageFluid_noBase64
                            }
                          }
                        }
                        url
                        alt
                      }
                    }
                    merchants {
                      merchant {
                        document {
                          ... on PrismicMerchant {
                            uid
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    banks {
                      bank {
                        document {
                          ... on PrismicBank {
                            uid
                            prismicId
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    credit_cards {
                      credit_card {
                        document {
                          ... on PrismicCreditCard {
                            uid
                            prismicId
                            data {
                              name
                              bank {
                                id
                              }
                              card_image {
                                alt
                                url
                                dimensions {
                                  width
                                  height
                                }
                                fixed(height: 42) {
                                  ...GatsbyPrismicImageFixed_noBase64
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    short_description {
                      text
                    }
                    tagged {
                      tag {
                        uid
                        type
                        document {
                          ... on PrismicTag {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    title
                    to(formatString: "LL", locale: "zh-tw")
                  }
                }
              }
            }
          }
          search_result_page_sponsor_offers {
            data: offer {
              url
              uid
              id
              isBroken
              document {
                ... on PrismicOffer {
                  prismicId
                  uid
                  type
                  data {
                    date
                    offer_category {
                      document {
                        ... on PrismicOfferCategory {
                          data {
                            name
                          }
                          uid
                          type
                        }
                      }
                    }
                    offer_subcategory {
                      document {
                        ... on PrismicOfferSubcategory {
                          uid
                          type
                          data {
                            name
                            offer_category {
                              uid
                              type
                            }
                            image {
                              url
                            }
                            image_color
                          }
                        }
                      }
                    }
                    featured_images {
                      image {
                        thumbnails {
                          thumbnail {
                            fluid(maxWidth: 240) {
                              ...GatsbyPrismicImageFluid_noBase64
                            }
                          }
                        }
                        url
                        alt
                      }
                    }
                    merchants {
                      merchant {
                        document {
                          ... on PrismicMerchant {
                            uid
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    banks {
                      bank {
                        document {
                          ... on PrismicBank {
                            uid
                            prismicId
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    credit_cards {
                      credit_card {
                        document {
                          ... on PrismicCreditCard {
                            uid
                            prismicId
                            data {
                              name
                              bank {
                                id
                              }
                              card_image {
                                alt
                                url
                                dimensions {
                                  width
                                  height
                                }
                                fixed(height: 42) {
                                  ...GatsbyPrismicImageFixed_noBase64
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    short_description {
                      text
                    }
                    tagged {
                      tag {
                        uid
                        type
                        document {
                          ... on PrismicTag {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                    title
                    to(formatString: "LL", locale: "zh-tw")
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicOffer(
      sort: { fields: data___date, order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        data: {
          date: {
            lte: $today
          }
        }
      }
    )  {
        nodes {
          data {
            offer_category {
              document {
                ... on PrismicOfferCategory {
                  data {
                    name
                  }
                  uid
                  type
                }
              }
            }
            offer_subcategory {
              document {
                ... on PrismicOfferSubcategory {
                  uid
                  type
                  data {
                    name
                    offer_category {
                      uid
                      type
                    }
                    image {
                      url
                    }
                    image_color
                  }
                }
              }
            }
            featured_images {
              image {
                url
                thumbnails {
                  thumbnail {
                    fluid(maxWidth: 240) {
                      ...GatsbyPrismicImageFluid_noBase64
                    }
                  }
                }
                alt
              }
            }
            merchants {
              merchant {
                document {
                  ... on PrismicMerchant {
                    uid
                    data {
                      name
                    }
                  }
                }
              }
            }
            banks {
              bank {
                document {
                  ... on PrismicBank {
                    uid
                    prismicId
                    data {
                      name
                    }
                  }
                }
              }
            }
            credit_cards {
              credit_card {
                document {
                  ... on PrismicCreditCard {
                    uid
                    prismicId
                    data {
                      name
                      bank {
                        id
                      }
                      card_image {
                        alt
                        url
                        dimensions {
                          width
                          height
                        }
                        fixed(height: 42){
                          ...GatsbyPrismicImageFixed_noBase64
                        }
                      }
                    }
                  }
                }
              }
            }
            short_description {
              text
            }
            tagged {
              tag {
                uid
                type
                document {
                  ... on PrismicTag {
                    id
                    data {
                      name
                    }
                  }
                }
              }
            }
            title
            to(formatString: "LL", locale: "zh-tw")
          }
          prismicId
          last_publication_date
          uid
          type
        }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
